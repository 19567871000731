/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();
                var content = 'html,body';
                if(viewportWidth < 768){
                    content = '.page-wrap';
                }


                // Stop scrolling the page when user inteacts
                // $('html,body').bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(){
                //     $(content).stop();
                // });


                // Smmoth scrolling script. Scrollable area depends on screensize.
                $('a[href*="#"]:not([href="#"])').click(function () {
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            if(viewportWidth > 768 || target.selector === '#top') {
                                $(content).animate({
                                  scrollTop: target.offset().top
                                }, 1000);
                                return false;
                            } else {
                                var main = $(".page-wrap:not(:animated)");
                                var destination = $(target).offset().top + main.scrollTop();
                                main.animate({ scrollTop: destination },500);
                                return false;
                            }
                        }
                    }
                });

                $('#booking_top_link').click(function(){
                    if($('#booking_div').is(':visible')) {
                        $('#booking_div').fadeOut();
                    } else {
                        $('#booking_div').fadeIn();
                    }

                    return false;
                })


                // Override smooth scroll script settings from beaverbuilder
                if ( 'undefined' !== typeof FLBuilderLayoutConfig ) {
                    FLBuilderLayoutConfig.anchorLinkAnimations.duration = 1000;
                    FLBuilderLayoutConfig.anchorLinkAnimations.easing = 'swing';
                    FLBuilderLayoutConfig.anchorLinkAnimations.offset = 0;
                }


                // Close old browser warning in IE lt 10 on click
                $('.close-oldie').click(function(){
                    $( ".oldie-warning" ).fadeOut();
                });


                // Toggles specific elements on specific scroll position
                $.fn.toggleElementsOnScroll = function() {
                    var viewportHeight = $(document).height();
                    var y = $(this).scrollTop();
                    if (y > viewportHeight/2) {
                        $('.to-top').removeClass('hidden');
                    } else {
                        $('.to-top').addClass('hidden');
                    }
                };


                // MediaQuery triggered functions - different scroll container for mobile & desktop
                var mqDesktop = Modernizr.mq('(min-width: 768px)');
                if (mqDesktop) {

                    // Scroll-Top-Btn / Floaty-Bar fading function
                    $(document).scroll(function() {
                        $(this).toggleElementsOnScroll();
                    });

                    // Define Vars for affix calcs
                    var nav = $('#desktop-nav');
                    var navHeight = $(nav).height();
                    var offsetAffix = $(nav).offset();

                    // Makes the nav sticky
                    $(nav).affix({
                        offset: {
                            top: offsetAffix.top // You may have to add some extra px for borders etc.
                        }
                    });

                    // Add padding to body to prevent lil' page-jump when nav is stickfied
                    $(nav).on('affix.bs.affix', function () {
                        $('body').css('padding-top', navHeight + 'px');
                        $('#booking_top').fadeOut();
                        $('#booking_top').fadeOut();
                        $("#desktop-nav").animate({height: "68px"}, '', function(){
                            $("#desktop-nav").addClass('small_nav');
                        });
                    });

                    // Remove the x-tra padding after nav gets static again
                    $(nav).on('affixed-top.bs.affix', function () {
                        $('body').css('padding-top', '0');
                        $('#booking_top').fadeIn();
                        $("#desktop-nav").animate({height: "98px"}, '', function(){
                            $("#desktop-nav").removeClass('small_nav');
                        });
                    });

                    // Recalculate offset for sticky element and position for mega dropdown centering on resize
                    $( window ).resize(function() {
                        var offsetAffix = $(nav).offset();
                    });
                }
                else {
                    // Scroll-Top-Btn / Floaty-Bar fading function
                    $('.page-wrap').scroll(function() {
                        $(this).toggleElementsOnScroll();
                    });
                }


                // Initialize doubletaptogo on desktop-nav for dropdown touch support
                if($('html').hasClass('touchevents')) {
                    $( '#desktop-nav li:has(ul)' ).doubleTapToGo();
                }


                // Slide up/down mobile topbar on scroll up/down
                var didScroll;
                var prev = 0;
                var topbar = $('.topbar');
                var topbarHeight = topbar.outerHeight();
                var offset = 100;

                $(content).scroll(function(event){
                    didScroll = true;
                });


                // Toggles mobile topbar on scroll
                function hasScrolled(){
                    var scrollTop = $(content).scrollTop();
                    if(scrollTop > topbarHeight + offset){
                        topbar.toggleClass('slide-up', scrollTop > prev);
                        prev = scrollTop;
                    }
                }

                setInterval(function() {
                    if (didScroll) {
                        hasScrolled();
                        didScroll = false;
                    }
                }, 100);

                if ($('.accordion').html() !== undefined) {
                    var more_text = 'mehr';
                    var collapse = 'einklappen';

                    $('.accordion').hide();
                    $('<a class="accordion_more" href="#"> ...'+more_text+'</a>').appendTo($('.accordion').prev('.fl-module').find('.fl-rich-text p').last('p'));

                    $('.accordion_more').on('click', function(event) {
                        event.preventDefault();
                        $(this).fadeOut();
                        $('.accordion').slideDown();

                        return false;
                    });
                }
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
